import { cn } from '../../utils/cn';

export const XIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? `w-4 h-4 fill-current stroke-current`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='0.707111'
        width='12.8533'
        height='1.30889'
        rx='0.654443'
        transform='matrix(0.707103 0.707111 -0.707103 0.707111 4.41895 2.49281)'
      />
      <path d='M12.545 3.45569C12.8006 3.71127 12.8006 4.12564 12.545 4.38122L4.38188 12.5444C4.12631 12.8 3.71194 12.8 3.45637 12.5444C3.20079 12.2888 3.20079 11.8745 3.45637 11.6189L11.6195 3.45569C11.875 3.20012 12.2894 3.20012 12.545 3.45569Z' />
    </svg>
  );
};

export const XMarkIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={cn('w-4 h-4 fill-current stroke-current', props.className)}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='0.707111'
        width='12.8533'
        height='1.30889'
        rx='0.654443'
        transform='matrix(0.707103 0.707111 -0.707103 0.707111 4.41895 2.49281)'
      />
      <path d='M12.545 3.45569C12.8006 3.71127 12.8006 4.12564 12.545 4.38122L4.38188 12.5444C4.12631 12.8 3.71194 12.8 3.45637 12.5444C3.20079 12.2888 3.20079 11.8745 3.45637 11.6189L11.6195 3.45569C11.875 3.20012 12.2894 3.20012 12.545 3.45569Z' />
    </svg>
  );
};

export function XBoldIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className ?? `w-3.5 h-3.5 fill-current`}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 10 10'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M2.143.714A1.01 1.01 0 10.714 2.143L3.571 5 .714 7.857a1.01 1.01 0 001.429 1.429L5 6.429l2.857 2.857a1.01 1.01 0 101.429-1.429L6.429 5l2.857-2.857A1.01 1.01 0 007.857.714L5 3.571 2.143.714z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
